import React from 'react';
import { Image, Button, Avatar } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

const Company = ({ item, showDetails }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-CA', {
            year: 'numeric'
        }).format(date);
    };
    return (
        <div className={`company-info cardmembership${item.membership_code}`}>
            <div className='company-main'>
                <div className='logo'>
                    {item.logo ? (
                        <Image
                            src={item.logo}
                            style={{ maxHeight: '100px' }}
                            preview={false}
                            placeholder={''}
                        />
                    ) : (
                        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{item.acronym}</div>
                    )}
                </div>
                <h1>{item.acronym}</h1>
                <h2>{item.name}</h2>
                {item.website && (
                    <div className='website'>
                        <Button style={{ opacity: '.5', marginLeft: 20 }} shape='circle' size='small' icon={<LinkOutlined />} href={item.website} target="_blank" rel="noopener noreferrer" title={`Visit ${item.acronym} website: ${item.website}`} />
                    </div>
                )}
                {!showDetails && <div className='membership'>{item.membership_label}{' '}
                    <Avatar size='small' style={{ boxShadow: '0 0 3px 1px #cccccc', opacity: '.8', border: 0, height: 10, width: 10, marginLeft: 3, marginBottom: 2 }} />
                    </div>}
            </div>
            {showDetails && (
                <div className='company-details'>
                    <div className='country'>
                        <Avatar size='small' shape='square' src={<Image
                            preview={false}
                            src={item.country_flag}
                            style={{ opacity: '.8', height: '25px', width: '30px' }}
                        />} style={{ boxShadow: '0 0 3px 1px #cccccc', border: 0, height: 15, width: 20, marginRight: 3 }} />
                        {' '}{item.country_name}
                    </div>
                    <div className='membership'>{item.membership_label}{' '}
                    <Avatar size='small' style={{ boxShadow: '0 0 3px 1px #cccccc', opacity: '.8', border: 0, height: 10, width: 10, marginLeft: 3, marginBottom: 2 }} />
                    </div>

                    <div className='since'><span>Member since:</span> {formatDate(item.accessiondate)}</div>
                    <div className='updated'><span>Updated:</span> {formatDate(item.lastupdate)}</div>

                </div>
            )}
        </div>
    );
};

export default Company;
