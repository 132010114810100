import React from 'react';
import { Drawer, Image, Avatar, Badge } from 'antd';
import Company from './Company';

const MapDrawer = ({ isOpen, onClose, title, items, flag }) => {
    const itemCount = items ? items.length : 0;

    return (
        <Drawer
            title={<><div style={{marginLeft: 10}}>
                        <Avatar size='small' shape='square' src={<Image
                            preview={false}
                            src={flag}
                            style={{ opacity: '.8', height: '25px', width: '30px' }}
                        />} style={{ boxShadow: '0 0 3px 1px #cccccc', border: 0, height: 15, width: 20, marginRight: 3 }} /> <Badge count={itemCount} overflowCount={999} style={{ backgroundColor: '#aaa' }} />
                        {' '}<br/>{title}
                    </div></>}
            placement="right"
            closable={true}
            onClose={onClose}
            open={isOpen}
            style={{ margin: 0 , padding: 0, paddingTop: 10, zIndex: 1500}}
        >
            
            {items && items.map((item, index) => (
                <div style={{ padding: 15, borderBottom: 'solid 1px #f5f5f5'}}>
                <Company key={item.id || index} item={item} />
                </div>
            ))}
   
        </Drawer>
    );
};

export default MapDrawer;
