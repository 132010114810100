import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Card, Col, Row, FloatButton } from 'antd';
import Company from './Company';
import ProgressBar from './ProgressBar';
import SortBar from './SortBar';

const CompanyGrid = ({ items, uicregion }) => {
  const [sortedItems, setSortedItems] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);
  const [itemCount, setItemCount] = useState(20);
  const loaderRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (sortedItems.length > 0) {
      setDisplayItems(sortedItems.slice(0, itemCount));
      setIsLoading(false);
    }
  }, [sortedItems, itemCount]);

  const handleIntersection = useCallback(entries => {
    const firstEntry = entries[0];
    if (firstEntry.isIntersecting) {
      setItemCount(prevItemCount => prevItemCount + 20);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '100px',
      threshold: 0.1
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => observer.disconnect();
  }, [handleIntersection]);

  const memoizedItems = useMemo(() => (
    displayItems.map((item, index) => (
      <Col
        key={item.id || `col-${index}`} // Utilisez un identifiant unique si disponible
        xs={{ flex: '100%' }}
        sm={{ flex: '50%' }}
        md={{ flex: '33.3333%' }}
        lg={{ flex: '20%' }}
        xl={{ flex: '16.6666%' }}
      >
        <Card className='card'>
          <Company item={item} showDetails={true} />
        </Card>
      </Col>
    ))
  ), [displayItems]);

  return (
  <>
  <ProgressBar loading={isLoading} />
    <div className='grid'>
      
      <SortBar items={items} setFilteredItems={setSortedItems} setIsLoading={setIsLoading} />
      <Row gutter={[25, 25]}>
        {memoizedItems}        
      </Row>
      <div ref={loaderRef} style={{ height: '100px' }} />
      <FloatButton.BackTop style={{ bottom: 90, right: 10 }} />
    </div>
    </>
  );
};

export default React.memo(CompanyGrid);
