import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Modal, Badge, Select, Avatar, AutoComplete, Slider, Popover, ConfigProvider, Alert } from 'antd';
import { ControlOutlined, CloseCircleOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';

const FilterSearch = ({ setFilteredItems, items = [], uicregion }) => {
    const [visible, setVisible] = useState(false);
    const [itemCount, setItemCount] = useState(items.length);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filterConfig, setFilterConfig] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const currentYear = new Date().getFullYear();

    const showDrawer = () => setVisible(true);
    const onClose = () => setVisible(false);

    const adjustSliderValueToYear = useCallback((value) => {
        const totalYears = currentYear - 1922;
        return Math.round((value / 100) * totalYears) + 1922;
    }, [currentYear]);

    const handleFilterConfigChange = useCallback((key, value) => {
        setFilterConfig(prev => {
            const newConfig = { ...prev };
            if (value.length > 0) {
                newConfig[key] = value;
            } else {
                delete newConfig[key];
            }
            return newConfig;
        });
    }, []);

    const handleApplyFilters = useCallback(() => {
        const startYear = filterConfig.accessiondate ? adjustSliderValueToYear(filterConfig.accessiondate[0]) : 1922;
        const endYear = filterConfig.accessiondate ? adjustSliderValueToYear(filterConfig.accessiondate[1]) : currentYear;

        const filteredData = items.filter(item => {
            const itemYear = new Date(item.accessiondate).getFullYear();
            return (!filterConfig.accessiondate || (itemYear >= startYear && itemYear <= endYear)) &&
                (!filterConfig.membership_label || filterConfig.membership_label.includes(item.membership_label)) &&
                (!filterConfig.uic_region || item.uic_region.split('|').map(r => r.trim()).some(r => filterConfig.uic_region.includes(r))) &&
                (!filterConfig.country_name || filterConfig.country_name.includes(item.country_name)) &&
                (!searchValue
                    || item.name.toLowerCase().includes(searchValue.toLowerCase())
                    || item.acronym.toLowerCase().includes(searchValue.toLowerCase())
                    || item.acronym === searchValue.split(' - ')[0]
                );
        });

        setFilteredItems(filteredData);
        setItemCount(filteredData.length);
        setIsFiltered(Object.keys(filterConfig).length > 0 || searchValue.length > 0);
    }, [filterConfig, searchValue, items, adjustSliderValueToYear, currentYear, setFilteredItems]);

    const debouncedApplyFilters = useMemo(() => debounce(handleApplyFilters, 300), [handleApplyFilters]);

    useEffect(() => {
        debouncedApplyFilters();
        return () => debouncedApplyFilters.cancel();
    }, [filterConfig, searchValue, debouncedApplyFilters]);

    const handleResetFilters = useCallback(() => {
        setFilterConfig({});
        setSearchValue('');
        setFilteredItems(items);
        setItemCount(items.length);
        setIsFiltered(false);
    }, [items, setFilteredItems]);

    const formatter = useCallback((value) => adjustSliderValueToYear(value).toString(), [adjustSliderValueToYear]);

    const membershipOptions = useMemo(() => [...new Set(items.map(item => item.membership_label))].map(value => ({
        value: value,
        label: value
    })), [items]);

    const uicRegionOptions = useMemo(() => [...new Set(items.flatMap(item => item.uic_region.split('|').map(region => region.trim())))].sort().map(value => ({
        value: value,
        label: value
    })), [items]);

    const countryOptions = useMemo(() => [...new Set(items.map(item => item.country_name))].sort().map(value => ({
        value: value,
        label: (
            <>
                <Avatar
                    src={items.find(item => item.country_name === value).country_flag}
                    size="small"
                    style={{ marginRight: 8, width: 16, height: 16 }}
                />
                {value}
            </>
        )
    })), [items]);

    const hoverContent = (
        <>
            {Object.keys(filterConfig).map(key => {
                if (key === 'accessiondate') {
                    return (<p key={key}>Accession date: {adjustSliderValueToYear(filterConfig[key][0])} - {adjustSliderValueToYear(filterConfig[key][1])}</p>);
                }
                if (key === 'membership_label') {
                    return (<p key={key}>Membership: {filterConfig[key].join(', ')}</p>);
                }
                if (key === 'uic_region') {
                    return (<p key={key}>UIC Region: {filterConfig[key].join(', ')}</p>);
                }
                if (key === 'country_name') {
                    return (<p key={key}>Country: {filterConfig[key].join(', ')}</p>);
                }
                return null;
            })}
            <p>Number of items found: <strong>{itemCount}</strong></p>
            {isFiltered && <Button icon={<ReloadOutlined />} onClick={handleResetFilters} title="Reset all Filters">Reset filters</Button>}
        </>
    );

    const filterButton = (
        <Button
            onClick={visible ? onClose : showDrawer}
            type={isFiltered ? 'primary' : 'default'}
            title={visible ? "Close Filters / Search" : "Show Filters / Search"}
            style={{
                position: 'fixed',
                top: 44,
                borderRadius: 2,
                right: -1,
                textAlign: 'center',
                zIndex: 1100,
                height: 60,
                padding: '2px 8px',
                backgroundColor: isFiltered ? '#1890ff' : ''
            }}
        >{visible ? <CloseCircleOutlined /> : <ControlOutlined />}
            <div><span className='d-sm-none'>filters</span></div>
            <Badge
                count={itemCount}
                overflowCount={999}
                style={{
                    width: '40px',
                    margin: '.2em auto .2em auto',
                    borderColor: 'transparent',
                    backgroundColor: isFiltered ? '#57bd84' : '#dddddd',
                    color: isFiltered ? '#ffffff' : '#000000'
                }}
            />
        </Button>
    );

    const autoCompleteOptions = useMemo(() => items.map(item => ({ value: `${item.acronym} - ${item.name}` })), [items]);

    return (
        <div style={{ color: isFiltered ? '#009bdf' : '#000000' }}>
            {isFiltered ?
                <Popover content={hoverContent} title={'Active filters:'} trigger="hover" placement="left">
                    {filterButton}
                </Popover> : filterButton
            }
            <Modal
                title={uicregion ? 'Region: ' + uicregion : ''}
                style={{ top: 48, right: 2, margin: '0 0 0 auto', maxWidth: 350 }}
                open={visible}
                closable={false}
                maskClosable={true}
                onCancel={onClose}
                footer=""
            >
                <div style={{ marginRight: 60 }}>
                    {itemCount === 0 && <Alert
      message="No results"
      description={<p>Please adjust your search criteria or <Button size='small' type='link' onClick={handleResetFilters}>reset all filters.</Button></p>}
      type="info"
      showIcon
      closable
    />}

<AutoComplete
                        style={{ width: '100%', marginRight: '1em' }}
                        value={searchValue}
                        onChange={setSearchValue}
                        placeholder={<><SearchOutlined /> Search by name or acronym</>}
                        options={autoCompleteOptions}
                        filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                    <Select
                        showSearch
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="UIC membership status"
                        className='select-filter'
                        autoClearSearchValue
                        value={filterConfig['membership_label']}
                        onChange={value => handleFilterConfigChange('membership_label', value)}
                        options={membershipOptions}
                    />
                    {!uicregion &&
                        <Select
                            showSearch
                            mode="multiple"
                            autoClearSearchValue
                            style={{ width: '100%' }}
                            placeholder="UIC region"
                            className='select-filter'
                            value={filterConfig['uic_region']}
                            onChange={value => handleFilterConfigChange('uic_region', value)}
                            options={uicRegionOptions}
                        />
                    }
                    <Select
                        showSearch
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Country / Area"
                        className='select-filter'
                        autoClearSearchValue
                        value={filterConfig['country_name']}
                        onChange={value => handleFilterConfigChange('country_name', value)}
                        options={countryOptions}
                    />
                    
                    <ConfigProvider
                        theme={{
                            components: {
                                Slider: {
                                    trackBg: 'yellow',
                                    trackHoverBg: 'orange',
                                    dotSize: '12',
                                    railBg: '#ddd',
                                    colorText: '#fff',
                                }
                            },
                        }}
                    >
                        <p style={{color: 'yellow'}}>Accession date:</p>
                        <Slider
                            tooltip={{ formatter }}
                            title='Accession date'
                            range
                            marks={{ 0: '1922', 100: currentYear.toString() }}
                            defaultValue={[0, 100]}
                            onChange={(value) => handleFilterConfigChange('accessiondate', value)}
                            value={filterConfig['accessiondate'] || [0, 100]}
                        />
                    </ConfigProvider>
                </div>
            </Modal>
        </div>
    );
};

export default FilterSearch;
