import React from 'react';
import { Popover, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const MapInfo = () => {
    const popoverContent = (
        <p style={{ maxWidth: 300, padding: 0, margin: 0, fontSize: 12, color: '#333' }}>
            The boundaries, names and designations used on the map do not represent an official UIC position on the legal status of any country or territory or the delimitation of its boundaries.
        </p>
    );

    return (
        <div style={{ position: 'absolute', bottom: 15, right: '49%', zIndex: 400 }}>
            <Popover content={popoverContent} title="" placement="top">
                <Button icon={<InfoCircleOutlined style={{ color: '#333' }} />} size="small" shape="circle" />
            </Popover>
        </div>
    );
};

export default MapInfo;
