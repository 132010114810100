import { useEffect } from 'react';
import NProgress from 'nprogress';

const ProgressBar = ({ loading }) => {
    useEffect(() => {
        NProgress.configure({ trickle: true, easing: 'ease', speed: 500, showSpinner: false, minimum: 0.4 });

        if (loading) {
            NProgress.start();
        } else {
            NProgress.done();
        }
    }, [loading]);

    return null; // Le composant ne rend rien
};

export default ProgressBar;
