// src/components/ByCountriesMap.js
import React, { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapDrawer from './MapDrawer';
import MapInfo from './MapInfo';
import 'mapbox-gl/dist/mapbox-gl.css';

import ProgressBar from './ProgressBar';

const ByCountriesMap = ({ items, uicregion }) => {
    const [map, setMap] = useState(null);
    const [visible, setVisible] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [loading, setLoading] = useState(true);
    const foundCountryColor = '#009bdf';

    useEffect(() => {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
        const newMap = new mapboxgl.Map({
            container: 'map-countries',
            style: 'mapbox://styles/aymericb/ckmnuxe1e0yis17o6pier7xyo',
            center: [0, 20],
            zoom: 2
        });

        newMap.on('load', () => {
            newMap.addSource('countries', {
                type: 'vector',
                url: 'mapbox://mapbox.country-boundaries-v1'
            });
            setLoading(false);
            setMap(newMap);
        });

        return () => newMap && newMap.remove();
    }, []);

    useEffect(() => {
        if (!map || !items.length) return;

        const groupedData = items.reduce((acc, item) => {

                acc[item.country_iso3] = acc[item.country_iso3] || [];
                acc[item.country_iso3].push(item);

            return acc;
        }, {});

        if (map.getLayer('countries-fill')) {
            map.removeLayer('countries-fill');
        }

        map.addLayer({
            id: 'countries-fill',
            type: 'fill',
            source: 'countries',
            'source-layer': 'country_boundaries',
            'generateId': true,
            paint: {
                'fill-color': [
                    'match',
                    ['get', 'iso_3166_1_alpha_3'],
                    ...Object.keys(groupedData).flatMap(key => [key, foundCountryColor]),
                    '#bbbbbb'
                ],
                'fill-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    0.5,
                    1
                ]
            }
        });

        map.on('mousemove', 'countries-fill', (e) => {
            const featureId = e.features[0].id;
            map.setFeatureState(
                { source: 'countries', sourceLayer: 'country_boundaries', id: featureId },
                { hover: true }
            );
        });

        map.on('mouseleave', 'countries-fill', () => {
            if (map.getLayer('countries-fill')) {
                map.removeFeatureState({ source: 'countries', sourceLayer: 'country_boundaries' });
            }
        });

        map.on('click', 'countries-fill', (e) => {
            const countryCode = e.features[0].properties.iso_3166_1_alpha_3;
            const countryItems = groupedData[countryCode] || [];
            if (countryItems.length > 0) {
                setSelectedCountry({
                    countryIso3: countryCode,
                    countryName: countryItems[0].country_name,
                    countryFlag: countryItems[0].country_flag,
                    items: countryItems
                });
                setVisible(true);
            }
        });

    }, [map, items, uicregion]);

    return (
        <div>
            <div id="map-countries" style={{ width: '100%', height: '92vh' }}></div>
            <ProgressBar loading={loading} />
            {!loading && (
                <>
                    <MapDrawer isOpen={visible} onClose={() => setVisible(false)} title={selectedCountry?.countryName} flag={selectedCountry?.countryFlag} items={selectedCountry?.items || []} />
                    <MapInfo context={uicregion} />
                </>
            )}
        </div>
    );
};

export default ByCountriesMap;
