import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button, Drawer, Image, AutoComplete, Input } from 'antd';
import { LinkOutlined, RightCircleOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import response from '../navigation.json';

const UicOrgNavigation = () => {
  const [menuData, setMenuData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedRootItem, setSelectedRootItem] = useState(null);
  const [selectedChildItem, setSelectedChildItem] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {

    const urlApi = "https://uic.org/?page=navigation.json";
    fetch(urlApi)
      .then(response => response.json())
      .then(data => {
        const dataWithChildren = data.map(item => ({ ...item, children: item.children || [] }));
        setMenuData(dataWithChildren);
        setFilteredData(dataWithChildren);
      });

    // const dataWithChildren = response.map(item => ({ ...item, children: item.children || [] }));
    // setMenuData(dataWithChildren);
    // setFilteredData(dataWithChildren);
  }, []);

  const createMenuTree = useCallback((menuData) => {
    const menuTree = [];
    const menuMap = {};

    menuData.forEach(item => {      
      menuMap[item.id] = { ...item, children: [] };
      if (item.parentId === "-1") {
        menuTree.push(menuMap[item.id]);
      } else {
        if (menuMap[item.parentId]) {
          menuMap[item.parentId].children.push(menuMap[item.id]);
        }
      }
    });

    const sortItems = (items) => {
      return items.sort((a, b) => {
        if (a.children.length > 0 && b.children.length === 0) return -1;
        if (a.children.length === 0 && b.children.length > 0) return 1;
        return a.name.localeCompare(b.name);
      });
    };

    sortItems(menuTree).forEach(rootItem => {
      rootItem.children = sortItems(rootItem.children);
    });

    return menuTree;
  }, []);

  const menuTree = createMenuTree(filteredData);

  const handleRootItemClick = (item) => {
    setSelectedRootItem(item);
    setSelectedChildItem(null);
  };

  const handleVisitUrl = (item) => {
    window.open(item.href, '_blank');
  };

  const handleChildItemClick = (item) => {
    setSelectedChildItem(item);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      const lowercasedValue = value.toLowerCase();
      const filtered = menuData.filter(item =>
        item.name.toLowerCase().includes(lowercasedValue) ||
        (item.h2 && item.h2.toLowerCase().includes(lowercasedValue))
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(menuData);
    }
  };

  const renderButtons = useCallback((menuItems, onClick) => {
    return menuItems.map(item => (
      <div key={item.id} style={{ padding: '0', display: 'flex', alignItems: 'center', justifyItems: 'center', marginBottom: 5 }}>
        <Button type='link' size='large' icon={<LinkOutlined />} onClick={() => handleVisitUrl(item)} />
        <div style={{ padding: '1.5em' }}>
        <div dangerouslySetInnerHTML={{ __html: item.name }}></div>
        <div dangerouslySetInnerHTML={{ __html: item.h2 }} style={{ display: 'block', fontSize: '0.8em', color: '#888' }}></div>
        </div>
        {item.children.length > 0 &&
          <Button type='link' size='large' style={{ marginLeft: 'auto', borderRadius: 0 }} icon={<RightCircleOutlined />}
            onClick={() => onClick(item)} />
        }
      </div>
    ));
  }, []);

  const showDrawer = () => setVisible(true);
  const onClose = () => {
    setVisible(false);
    setSearchValue('');
    setFilteredData(menuData);
    setSelectedRootItem(null);
    setSelectedChildItem(null);
  };

  const clearSearch = () => {
    setSearchValue('');
    setFilteredData(menuData);
    setSelectedRootItem(null);
    setSelectedChildItem(null);
  };

  return (
    <>
      <Button type='link' onClick={visible ? onClose : showDrawer}>
        <Image src="./uic_logo.svg" alt="UIC" preview={false} height={30} />
      </Button>
      <Drawer
        open={visible}
        closable={true}
        onClose={onClose}
        width="100%"
        placement="left"
        style={{ marginTop: '64px' }}
        title={<>
          <h2><Image src="./uic_logo.svg" alt="UIC" preview={false} height={30} /> UIC.org website</h2>
          <AutoComplete
            value={searchValue}
            onChange={handleSearch}
            placeholder="Search by name or subtitle"
            style={{ width: '300px', margin: '1em' }}
          >
            <Input suffix={searchValue ? <CloseOutlined onClick={clearSearch} /> : <SearchOutlined />} />
          </AutoComplete></>}
      >



        <Row style={{ height: '100%' }}>
          {searchValue ? (
            <Col span={24} style={{ padding: '10px', overflowY: 'auto' }}>
              {renderButtons(filteredData, () => { })}
            </Col>
          ) : (
            <>
              <Col span={8} style={{ borderRight: '1px solid #f0f0f0', padding: '10px', overflowY: 'auto' }}>
                {renderButtons(menuTree, handleRootItemClick)}
              </Col>
              <Col span={8} style={{ borderRight: '1px solid #f0f0f0', padding: '10px', overflowY: 'auto' }}>
                {selectedRootItem && renderButtons(selectedRootItem.children, handleChildItemClick)}
              </Col>
              <Col span={8} style={{ padding: '10px', overflowY: 'auto' }}>
                {selectedChildItem && renderButtons(selectedChildItem.children, () => { })}
              </Col>
            </>
          )}
        </Row>
      </Drawer>
    </>
  );
};

export default UicOrgNavigation;
