import React, { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapInfo from './MapInfo';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Image, Button } from 'antd';
import { Popover } from 'antd';
import Company from './Company';
import { CloseCircleOutlined } from '@ant-design/icons';
import ProgressBar from './ProgressBar';

const MarkersMap = ({ items, uicregion }) => {
    const [map, setMap] = useState(null);
    const [loading, setLoading] = useState(true);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
    const [selectedItem, setSelectedItem] = useState(null);
    const [hoverPopoverVisible, setHoverPopoverVisible] = useState(false);
    const [closeTimer, setCloseTimer] = useState(null);
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    useEffect(() => {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
        const newMap = new mapboxgl.Map({
            container: 'map-markers',
            style: 'mapbox://styles/aymericb/ckmnuxe1e0yis17o6pier7xyo',
            center: [0, 20],
            zoom: 2
        });

        newMap.on('load', () => {
            setMap(newMap);
            setLoading(false);
        });

        newMap.on('click', () => {
            setPopoverVisible(false);
            setHoverPopoverVisible(false);
        });

        newMap.on('zoom', () => {
            setPopoverVisible(false);
            setHoverPopoverVisible(false);
        });

        newMap.on('move', () => {
            setPopoverVisible(false);
            setHoverPopoverVisible(false);
        });

        return () => newMap && newMap.remove();
    }, []);

    useEffect(() => {
        if (!map) return;

        if (!map.getSource('markers')) {
            map.addSource('markers', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: []
                },
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50
            });
        }

        const features = items.map(item => {
            const match = item.coordinates.match(/\(([^)]+)\)/);
            if (!match) return null;
            const coordinates = match[1].split(' ');
            return {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [parseFloat(coordinates[0]), parseFloat(coordinates[1])]
                },
                properties: item
            };
        }).filter(feature => feature !== null);

        map.getSource('markers').setData({
            type: 'FeatureCollection',
            features: features
        });

        if (!map.getLayer('clusters')) {
            map.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'markers',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        20, 10, 30, 100, 40
                    ],
                    'circle-color': '#007cbf'
                }
            });
            map.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'markers',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-size': 12
                },
                paint: {
                    'text-color': '#ffffff'
                }
            });
        }

        if (!map.getLayer('unclustered-point')) {
            map.addLayer({
                id: 'unclustered-point',
                type: 'circle',
                source: 'markers',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-radius': 6,
                    'circle-color': '#007cbf'
                }
            });

            const handleMouseEnter = (e) => {
                if (closeTimer) {
                    clearTimeout(closeTimer);
                    setCloseTimer(null);
                }
                const properties = e.features[0].properties;
                setSelectedItem(properties);
                setHoverPopoverVisible(true);
                const canvas = map.getCanvasContainer();
                const rect = canvas.getBoundingClientRect();
                setClickPosition({
                    x: e.originalEvent.clientX - rect.left,
                    y: e.originalEvent.clientY - rect.top
                });
            };

            const handleMouseLeave = () => {
                const timer = setTimeout(() => {
                    setHoverPopoverVisible(false);
                }, 2000);
                setCloseTimer(timer);
            };

            const handleClick = (e) => {
                const properties = e.features[0].properties;
                setSelectedItem(properties);
                setPopoverVisible(true);
                setHoverPopoverVisible(false);
                const canvas = map.getCanvasContainer();
                const rect = canvas.getBoundingClientRect();
                setClickPosition({
                    x: e.originalEvent.clientX - rect.left,
                    y: e.originalEvent.clientY - rect.top
                });
            };

            if (isTouchDevice) {
                map.on('click', 'unclustered-point', handleClick);
            } else {
                map.on('mouseenter', 'unclustered-point', handleMouseEnter);
                map.on('mouseleave', 'unclustered-point', handleMouseLeave);
            }
        }

    }, [map, items, closeTimer, isTouchDevice]);

    const simplePopoverContent = selectedItem ? (
        <Button
            type='link'
            onClick={(e) => {
                e.preventDefault();
                setPopoverVisible(true);
                setHoverPopoverVisible(false);
            }}>
            <Image src={selectedItem.logo} height={40} placeholder={selectedItem.acronym} preview={false} />
            <p style={{ background: '#ffffff', padding: 5, borderRadius: 5, color: '#333333', fontSize: '.8em' }}>details</p>
        </Button>
    ) : null;

    const fullPopoverContent = selectedItem ? (
        <>
            <Company item={selectedItem} showDetails={true} />
            <Button shape='circle' size='small' icon={<CloseCircleOutlined />} onClick={() => setPopoverVisible(false)} style={{ position: 'absolute', top: '10px', right: '10px' }} />
        </>
    ) : null;

    return (
        <>
            <div id="map-markers" style={{ width: '100%', height: '92vh' }}></div>
            <ProgressBar loading={loading} />
            {(!loading &&
                <MapInfo context={uicregion} />
            )}
            {selectedItem && (
                <>
                    <Popover
                        content={simplePopoverContent}
                        open={hoverPopoverVisible}
                        style={{ position: 'absolute', left: `${clickPosition.x}px`, top: `${clickPosition.y}px` }}
                    >
                        <div style={{ height: '0', width: '0', position: 'absolute', left: `${clickPosition.x}px`, top: `${clickPosition.y}px` }}></div></Popover>
                    <Popover
                        content={fullPopoverContent}
                        open={popoverVisible}
                        style={{ position: 'absolute', left: `${clickPosition.x}px`, top: `${clickPosition.y}px` }}
                        overlayStyle={{ width: '300px' }}
                    ><div style={{ height: '0', width: '0', position: 'absolute', left: `${clickPosition.x}px`, top: `${clickPosition.y}px` }}></div></Popover>
                </>
            )}
        </>
    );
};

export default MarkersMap;
