import React from "react";
import { Layout, Typography } from "antd";

const { Footer } = Layout;
const { Text, Link } = Typography;

export default function AppFooter() {
  return (
    <Footer style={{ textAlign: 'right', padding: 5 }}>
      <Text style={{ fontSize: 12 }}>
        © UIC - International union of railways. {new Intl.DateTimeFormat('en-GB', {year: 'numeric'}).format(new Date())}. All rights reserved.
        <Link href="https://uic.org/more/disclaimer" style={{ marginLeft: 10 }}>
          Legal disclaimer
        </Link>
      </Text>
    </Footer>
  );
}