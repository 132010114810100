import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const SortBar = ({ setFilteredItems, items = [], setIsLoading }) => {
  const [sortConfig, setSortConfig] = useState({
    field: 'name',
    direction: 'asc'
  });

  const sortOptions = [
    ['Name', 'name'],
    ['Acronym', 'acronym'],
    ['Accession date', 'accessiondate']
  ];

  // Fonction pour gérer le changement de tri
  const handleSortChange = (field) => {
    setIsLoading(true);
    setSortConfig(prevConfig => ({
      field,
      direction: prevConfig.field === field ? (prevConfig.direction === 'asc' ? 'desc' : 'asc') : 'asc'
    }));
  };

  // Fonction pour appliquer le tri
  useEffect(() => {
    const sortedData = [...items].sort((a, b) => {
      const aValue = a[sortConfig.field];
      const bValue = b[sortConfig.field];
      if (sortConfig.direction === 'asc') {
        return aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' });
      } else {
        return bValue.localeCompare(aValue, undefined, { numeric: true, sensitivity: 'base' });
      }
    });
    setFilteredItems(sortedData);
    setIsLoading(false);
  }, [sortConfig, items, setFilteredItems, setIsLoading]);

  // Affichage des boutons de tri
  return (
    <div className='sortbar'>
      {sortOptions.map(([label, field]) => (
        <Button
          key={field}
          size='small'
          onClick={() => handleSortChange(field)}
          icon={sortConfig.field === field ? (sortConfig.direction === 'asc' ? <ArrowDownOutlined style={{ color: '#009bdf' }} /> : <ArrowUpOutlined style={{ color: '#009bdf' }} />) : <ArrowDownOutlined style={{ color: '#f5f5f5' }} />}
          type='text'
          color={sortConfig.field === field ? "red" : "blue"}
          style={{ margin: '0 0.5em' }}
          title={`sort items by ${field}`}
        >
          {label}
        </Button>
      ))}
    </div>
  );
};

export default SortBar;
