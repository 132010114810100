// src/App.js
import React, { useState, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import ReactGA from 'react-ga';
import { Alert, FloatButton, Layout, Menu } from 'antd';
import { EnvironmentOutlined, GlobalOutlined, AppstoreOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import CompanyGrid from './components/CompanyGrid';
import MarkersMap from './components/MarkersMap';
import ByCountriesMap from './components/ByCountriesMap';
import FilterSearch from './components/FilterSearch';
import ProgressBar from './components/ProgressBar';
import UicOrgNavigation from './components/UicOrgNavigation';
import './App.css';
import Footer from './components/Footer';

const TRACKING_ID = "G-M1HCNMT065";
ReactGA.initialize(TRACKING_ID);

const { Header, Content } = Layout;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000, // 1 minute
      cacheTime: 300000, // 5 minutes
      refetchOnWindowFocus: false,
    },
  },
});

function MembersViews() {
  const [filteredItems, setFilteredItems] = useState([]);
  const [view, setView] = useState('grid');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const queryParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const uicregion = useMemo(() => queryParams.get('uicregion'), [queryParams]);

  const { isLoading, error, data } = useQuery({
    queryKey: ['repoData', uicregion],
    queryFn: () =>
      axios
        .get('https://backo.uic.org/api/companies/uicmembers')
        .then(res => {
          const filteredData = res.data.filter(item =>
            !uicregion || item.uic_region.split('|').map(r => r.trim()).includes(uicregion)
          );
          return filteredData;
        }),
    onSuccess: (data) => {
      setFilteredItems(data);
    }
  });

  const handleFullscreenToggle = useCallback(() => {
    const elem = document.documentElement;
    if (!isFullscreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { // Firefox
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { // IE/Edge
        elem.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  }, [isFullscreen]);

  const ViewComponent = useMemo(() => {
    switch (view) {
      case 'grid':
        return <CompanyGrid items={filteredItems.length > 0 ? filteredItems : data} uicregion={uicregion} />;
      case 'markers':
        return <MarkersMap items={filteredItems.length > 0 ? filteredItems : data} uicregion={uicregion} />;
      case 'countries':
        return <ByCountriesMap items={filteredItems.length > 0 ? filteredItems : data} uicregion={uicregion} />;
      default:
        return null;
    }
  }, [view, filteredItems, data, uicregion]);

  if (isLoading) return <ProgressBar loading={isLoading} />;
  if (error) return (
    <Alert
      message="Error"
      description={error.message}
      type="warning"
      showIcon
      closable
    />
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ display: 'flex', alignItems: 'center', padding: '0 16px', background: '#fff', zIndex: 900 }}>
        <div>
          <UicOrgNavigation />
          {uicregion && (
          <span className='context'>{uicregion}</span>
        )}
</div>
        <Menu mode="horizontal" theme="light" selectedKeys={[view]} style={{ flex: 1, justifyContent: 'flex-start' }}>
          <Menu.Item key="grid" icon={<AppstoreOutlined />} onClick={() => setView('grid')}>
            Grid view
          </Menu.Item>
          {/* <Menu.Item key="markers" icon={<EnvironmentOutlined />} onClick={() => setView('markers')}>
            Markers
          </Menu.Item> */}
          <Menu.Item key="countries" icon={<GlobalOutlined />} onClick={() => setView('countries')}>
            Map view
          </Menu.Item>
        </Menu>
        <FloatButton
          size='small'
          icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
          onClick={handleFullscreenToggle}
          title={isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
          style={{ right: 10, bottom: 30 }}
        />
      </Header>
      {data && <FilterSearch items={data} setFilteredItems={setFilteredItems} uicregion={uicregion} />}
      <Content>      
        {ViewComponent}
      </Content>
      <Footer style={{ textAlign: 'right', height: '200px', padding: '20px 50px' }}/>
      <ReactQueryDevtools initialIsOpen={false} />
    </Layout>
  );
}

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MembersViews />
    </QueryClientProvider>
  );
}

const rootElement = document.getElementById('root');
ReactDOM.createRoot(rootElement).render(<App />);
